import React, { FC, useContext } from "react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { RichTextBase, RichTextProps, ColumnType } from "../generated-types";
import { Box, Flex } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { themable } from "~/foundation/Theme";
import { RichTextStyling, RichTextStylingSmall } from "~/foundation/Components/RichText/styles";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

const RichText: FC<RichTextProps> = ({ fields, params, rendering }) => {
	const smallerPadding = params?.spacing === "small" ? true : false;
	const desktopPaddingY = smallerPadding ? "var(--chakra-sizes-modulePY__MD)" : "var(--chakra-sizes-modulePY__XL)";

	if (rendering.customData?.columnType === ColumnType.OneColumn || rendering.customData?.columnType === ColumnType.TwoColumn) {
		return (
			<ContentWrapper py={["0", null]} pb={["12", null, "16"]}>
				<RenderRichText fields={fields} params={params} rendering={rendering} richTextSmall={true} />
			</ContentWrapper>
		)
	}

	return (
		<Ribbon>
			<ContentWrapper py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, desktopPaddingY]}>
				<RenderRichText fields={fields} params={params} rendering={rendering} richTextSmall={false} />
			</ContentWrapper>
		</Ribbon>
	);
};

type RenderRichTextProps = RichTextProps & {
	richTextSmall: boolean;
}

const RenderRichText: FC<RenderRichTextProps> = ({ fields, params, richTextSmall }) => {
	const { sitecoreContext } = useSitecoreContext<RichTextBase>();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [theme] = useContext(ColorThemeContext);

	const columnCount = params.columns;
	const desktopAlignment = columnCount === "1" ? "start" : "center";

	return (
		<Flex justifyContent={["none", null, desktopAlignment]}>
			<Box
				className={sitecoreContext.pageEditing ? "editmode" : ""}
				flexBasis={["100%", null, columnCount ? "100%" : "60%"]}
				maxW={["100%", null, columnCount ? "100%" : "60%"]}
				{...richTextSmall ? { ...RichTextStylingSmall(theme, isRtl, columnCount) } : { ...RichTextStyling(theme, isRtl, columnCount) }}
			>
				{fields?.textContent
					? <sc.RichText className="contentDescription" field={fields.textContent} enableWordExplainer />
					: <sc.RichText className="contentDescription" field={sitecoreContext.route?.fields?.textContent} enableWordExplainer />
				}
			</Box>
		</Flex>
	);
};

export default themable()(RichText);
